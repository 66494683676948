.brand-name {
  position: fixed;
  top: 15px;
  left: 20px;
  font-size: 18px;
  font-weight: bold;
}
.auth-screen {
  overflow: hidden;
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #4cb8e6 50%);
}

.auth-left {
  height: 100vh;
  background-color: #f7faff;
  /* margin-top: 100px; */
}

.form-cover {
  height: 100vh;
}

.form-content {
  width: 70%;
  /* box-shadow: 0 0 20px #dadadaf4; */
}

.form-title {
  font-size: 20px;
  font-weight: 600;
}

.form-caption {
  /* color: #a3a3a3; */
  font-size: 15px;
  font-weight: 300;
}

li {
  font-size: 15px;
}

.auth-btn {
  padding: 10px;
  font-size: 16px;
}

.auth-left__content {
  width: 90%;
  padding-top: 50px;
}

.auth-header {
  font-size: 19px;
  font-weight: 500;
  color: #ffa58733;
}

.auth-text {
  font-size: 40px;
  font-weight: 600;
}

.auth-helper {
  font-size: 20px;
  font-weight: 300;
}

.auth-cta {
  font-size: 16px;
  color: #e9f0ff;
}

.email-error {
  outline: 3px solid #ff927f63;
}

.form-input {
  height: 40px;
  font-size: 13px;
}

.form-text {
  font-weight: 300;
  font-size: 13px;
}

.password {
  height: 100%;
  border: none;
}

.password::placeholder {
  color: grey;
  font-size: 14px;
}

.password-cover {
  height: 45px;
}

.password:focus {
  outline: none;
}

.eye-icon {
  cursor: pointer;
}

@media (max-width: 550px) {
  .form-cover {
    align-items: start !important;
  }
  .form-content {
    width: 90%;
  }
}
