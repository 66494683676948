td,
th {
  text-align: center;
  padding: 15px !important;
  color: #3d3f40;
}

td {
  font-weight: 400 !important;
  white-space: nowrap !important;
}

tr {
  cursor: pointer !important;
}

th {
  font-size: 14px !important;
  white-space: nowrap !important;
}

.table {
  overflow: auto;
}

thead {
  position: sticky !important;
  top: 0 !important;
  background-color: #fff !important;
  box-shadow: 0 0 5px #eeeeeede;
}

.file-img {
  width: 120px;
  height: 120px;
}

.file-title {
  font-size: 17px;
  font-weight: 510;
}

.file-caption {
  font-size: 14px;
  font-weight: 400;
}
