.server-caption {
  font-size: 14px;
  font-weight: 400;
}

.server-title {
  font-weight: 500;
  font-size: 16px;
}
.server-card {
  height: auto;
}

.server-img {
  width: 70px;
  height: 70px;
}
