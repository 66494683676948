.company-tab__cover {
  border-bottom: 1px solid #eee;
  color: #a9a9a9;
}

.company-tab {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
