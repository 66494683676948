.nav {
  height: 65px;
  z-index: 10;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0 3px 5px #eeeeeede;
}

.upgrade-cover {
  background-color: #8dbeff5d;
}

.notify-icon {
  font-size: 24px;
}

.profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  /* background: url("https://images.unsplash.com/photo-1665510383805-2394dc4d3323?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=900&q=60");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
}

.date-range {
  font-size: 15px;
  font-weight: 500;
}
