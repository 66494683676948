.card-img {
  width: 55px;
  height: 50px;
  border-radius: 50%;
}

.top-card {
  height: 120px;
  /* color: #3a9d3a; */
}

.card-img:nth-child(1) {
  background-color: #ffb19563;
}

.card-img:nth-child(2) {
  background-color: #006eff31;
  background-color: #99c5ff65;
  background-color: #a86bfd3b;
  background-color: #ffb2a44c;
}

.number {
  background: #ff85b85b !important;
  /* color: #c3ffc3;
  color: #ffcac0;
  color: #c9e4ff; */
}

.status {
  background: #006eff23 !important;
}

.uptime {
  background: #00804d1d !important;
}

.card-caption {
  font-size: 30px;
  font-weight: bold;
  color: #2b3648;
}

.card-icon {
  color: orange;
  font-size: 20px;
}

.title {
  font-size: 13px;
  color: #6d6d6d;
  margin-top: -10px;
  /* color: #0e990e; */
}

.top-card_info {
  font-size: 13px;
  font-weight: 300;
}

.top-card_info:nth-child(1) {
  color: green;
  background: #6fed6f33;
  font-weight: 400;
}

.top-card_info:nth-child(2) {
  color: tomato;
  background: #ff796123;
  font-weight: 400;
}

.color {
  color: #ffb7aa4f;
}
