* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px !important;
}

.caption {
  font-size: 13px !important;
}

.backdrop {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #0c255613;
  z-index: 100 !important;
  top: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  left: 0px;
  align-items: center;
  justify-content: center;
}
