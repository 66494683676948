.analytics-cover {
  height: auto;
  margin-bottom: 200px;
}

.page-title {
  font-weight: 510;
  font-size: 18px;
}

.data-point {
  background-color: #eeeeeeb3;
  height: 100px;
}

.data-point__title {
  font-weight: 300;
}

.data-point__value {
  font-weight: 510;
  /* color: #fe6145c9; */
}
