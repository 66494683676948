.main-cover {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.left-container {
  width: 250px;
  height: 100vh;
  background-color: #111827;
  /* background-color: #f0f5ff; */
}

.right-container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f9f9f9;
}

@media (max-width: 600px) {
  .left-container {
    display: none;
  }
}
