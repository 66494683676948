.submeter-modal_one {
  width: 450px;
  height: auto;
  position: relative;
}

.addsubmeter-desc {
  font-size: 14px;
  font-weight: 300px;
}
submeter-modal .addsubmeter-title {
  font-size: 15px;
  font-weight: 500;
}
.addsubmeter-icon {
  width: 80px;
  background: #1e8fff12;
  height: 60px;
  margin-right: 10px;
}

.addsubmeter-cover {
  height: auto !important;
  transition: all 150ms;
  cursor: pointer;
}

.addsubmeter-cover:hover {
  outline: 1px solid #1e8fff32 !important;
}
