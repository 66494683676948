.submeter-info_modal {
  width: 650px;
  height: auto;
  position: relative;
  top: -50px;
}

.addsubmeter-desc {
  font-size: 14px;
  font-weight: 300px;
}

.addsubmeter-title {
  font-size: 15px;
  font-weight: 500;
}
.addsubmeter-icon {
  width: 80px;
  background: #1e8fff12;
  height: 60px;
  margin-right: 10px;
}

.addsubmeter-cover {
  height: 180px;
  transition: all 150ms;
  cursor: pointer;
}

.addsubmeter-cover:hover {
  outline: 3px solid #1e8fff32;
}

.submeter-cover {
  width: 20px;
  height: 20px;
}

.submeter-entry {
  border-bottom: 1px solid #eee;
}

.submeter-modal_title {
  font-size: 14px;
  font-weight: 400;
}

.submeter-modal_value {
  font-size: 16px;
  font-weight: 500;
}
