.model-value {
  background: #1e8fff0b;
  height: 100%;
}

.model-title {
  font-size: 15px;
  font-weight: 500;
}

.model-desc {
  font-size: 14px;
  font-weight: 400;
}
.model-entry {
  border-bottom: 1px solid #eee;
}
.model-img {
  width: 40px;
  height: 40px;
  background: #5cd95c22;
}
