.meter-modal__cover {
  width: 650px;
  height: auto;
  margin-top: -100px;
}

.modal-title {
  font-size: 17px;
  font-weight: 500;
}

.form-label {
  font-size: 15px;
}

.form-control::placeholder {
  font-size: 15px;
  color: grey;
  font-weight: 400;
}

.form-control {
  height: 45px;
}
