.channels-title {
  font-size: 15px;
}

.form-search {
  border: 1px solid #c9c9c9;
  font-size: 13px;
}

.form-search:focus {
  outline: 2px solid #c9c9c956;
}

.form-search:focus {
  outline: none;
}

.form-search::placeholder {
  font-size: 13px;
  font-weight: 300;
}
