.company-profile {
  width: 100%;
  height: 300px;
  background-color: #f4f7fe;
}

.company-entry_title {
  color: #596576;
  font-size: 14px;
}

.company-entry {
  /* border-bottom: 1px solid #eee; */
}

.company-email::placeholder {
  font-size: 13px;
  font-weight: 300;
}

.key-cover {
  position: relative;
}

.key-plate {
  position: absolute;
  width: 100%;
  top: 1px;
  left: 0;
  height: 35px;
  background-color: #f3f3f3;
  cursor: pointer;
}
