td,
th {
  text-align: center;
  padding: 15px !important;
  color: #3d3f40;
}

td {
  font-weight: 300 !important;
  font-size: 13px !important;
}

tr {
  cursor: pointer !important;
}

th {
  font-size: 14px !important;
}

.table {
}
