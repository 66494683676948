.submeter-add_modal {
  width: 400px;
  height: auto;
  margin-top: -100px;
}

.modal-title {
  font-size: 17px;
  font-weight: 400;
}

.form-label {
  font-size: 13px;
}

.form-control::placeholder {
  font-size: 13px;
  color: grey;
  font-weight: 300 !important;
}

.form-control {
  height: 45px;
  font-size: 14px !important;
}
